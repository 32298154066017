import { date, isEmpty, maxDate, minDate, required } from '@ahmdigital/form-validation';
import getMelbourneTimezoneMoment from '@ahmdigital/logic/lib/utils/get-melbourne-timezone-moment';
import moment from 'moment';

import constants from '../ahm-constants';

export const isFirstPaymentBeforeStart = (message?: string) => (value?: any, data?: any) => {
  // Check that date is not before coverStartDate
  if (!isEmpty(value) && !isEmpty(data.coverStartDate)) {
    const coverStartDateValue = moment.tz(
      data.coverStartDate,
      constants.DATE_FORMAT.STANDARD,
      true,
      'Australia/Melbourne',
    );
    const paymentStartDateValue = moment.tz(value, constants.DATE_FORMAT.STANDARD, true, 'Australia/Melbourne');
    if (
      coverStartDateValue.isValid() &&
      paymentStartDateValue.isValid() &&
      paymentStartDateValue.isBefore(coverStartDateValue)
    ) {
      return message || 'Date of first payment cannot be before cover start date';
    }
  }

  return null;
};

// @ts-expect-error - Automatic, Please fix when editing this file
export const coverStartDate = () => (value) => {
  const now = getMelbourneTimezoneMoment();
  const twoMonthsFromNow = now.clone().add(2, 'months');

  const valueInMelbourneTimezone = moment.tz(value, constants.DATE_FORMAT.STANDARD, true, 'Australia/Melbourne');

  return (
    required('Select a cover starting date')(value) ||
    date()(value) ||
    minDate(now)(valueInMelbourneTimezone) ||
    maxDate(twoMonthsFromNow)(valueInMelbourneTimezone)
  );
};

// @ts-expect-error - Automatic, Please fix when editing this file
export const paymentStartDate = () => (value, data) => {
  const now = getMelbourneTimezoneMoment();
  const twoDaysFromNow = now.clone().add(2, 'days');
  const twoMonthsFromNow = now.clone().add(2, 'months');

  const valueInMelbourneTimezone = moment.tz(value, constants.DATE_FORMAT.STANDARD, true, 'Australia/Melbourne');

  return (
    required('Select a payment starting date')(value) ||
    date()(value) ||
    minDate(twoDaysFromNow)(valueInMelbourneTimezone) ||
    maxDate(twoMonthsFromNow)(valueInMelbourneTimezone) ||
    isFirstPaymentBeforeStart()(value, data)
  );
};
