import { filter, find, get, includes, isNull, parseInt } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { PRODUCT_TYPE } from '@ahmdigital/constants';
import { REHYDRATE } from 'redux-persist';

import {
  CLEAR_CART,
  EXTRAS_CHANGE,
  HOSPITAL_CHANGE,
  HOSPITAL_EXCESS_CHANGE,
  PAYMENT_SUBMIT_SUCCESS,
  PRODUCT_CHANGE,
} from '../../actions/action-types';
import getSaleableProductIds from '../../utils/get-saleable-product-ids';
import ProductService from '../../models/product';
import setComplementary from './setComplementary';

const radix = 10;

const emptyProduct = Immutable.from({
  id: null,
  name: null,
  rank: null,
  scales: null,
  type: null,
});
const initialState = Immutable.from({
  baseProductType: null,
  extras: emptyProduct,
  hospital: emptyProduct,
  product: emptyProduct,
});

const cartReducer = (oldState = initialState, action = {}) => {
  const state = Immutable.from(oldState);
  const reducers = {
    [CLEAR_CART]: () => initialState,
    [EXTRAS_CHANGE]: setComplementary({
      // @ts-expect-error - Automatic, Please fix when editing this file
      product: action.product,
      productType: PRODUCT_TYPE.EXTRAS,
      state,
    }),
    [HOSPITAL_CHANGE]: setComplementary({
      // @ts-expect-error - Automatic, Please fix when editing this file
      product: action.product,
      productType: PRODUCT_TYPE.HOSPITAL,
      state,
    }),
    [HOSPITAL_EXCESS_CHANGE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { hospitalExcess, products } = action;

      // get products with excess via parent product
      // hospital or package
      const productParent = find(
        {
          id: get('hospital.id', state) || get('product.id', state),
        },
        products,
      );

      const productParentId = get('parentProductId', productParent);

      const productsWithExcess = filter(
        {
          parentProductId: productParentId,
        },
        products,
      );

      // find product with charges.excess.amount that matches selected hospitalExcess
      const hospitalExcessProduct = find(
        {
          charges: {
            excess: {
              amount: parseInt(radix, hospitalExcess),
            },
          },
        },
        productsWithExcess,
      );

      const productAsCartObject = ProductService.getCartObject(hospitalExcessProduct);

      // Complementary only, we do this to keep complementary in state when changing excess
      if (get('extras.id', state)) {
        return setComplementary({
          product: hospitalExcessProduct,
          productType: PRODUCT_TYPE.HOSPITAL,
          state,
        })();
      }

      // Hospital only or package
      return Immutable.merge(initialState, {
        baseProductType: productAsCartObject.type,
        // @ts-expect-error - Automatic, Please fix when editing this file
        [productAsCartObject.type]: productAsCartObject,
        product: productAsCartObject,
      });
    },
    [PAYMENT_SUBMIT_SUCCESS]: () => initialState,
    [PRODUCT_CHANGE]: () => {
      const type = get('product.type', action);

      if (get('product.id', action) === get(`${type}.id`, state)) {
        return Immutable.set(state, 'baseProductType', type);
      }

      // @ts-expect-error - Automatic, Please fix when editing this file
      const cartObject = ProductService.getCartObject(action.product);
      const baseState = Immutable.merge(initialState, {
        baseProductType: type,
        product: cartObject,
      });

      if (isNull(type)) {
        return baseState;
      }

      return Immutable.set(baseState, type, cartObject);
    },
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'cart') {
        // Ensure that cart is empty
        if (!isNull(get('baseProductType', state))) {
          return state;
        }

        // Ensure that product being rehydrated is saleable
        if (!includes(get('payload.product.id', action), getSaleableProductIds())) {
          return state;
        }

        // @ts-expect-error - Automatic, Please fix when editing this file
        return { ...initialState, ...action.payload };
      }

      return state;
    },
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type]
    ? // @ts-expect-error - Automatic, Please fix when editing this file
      Immutable.asMutable(reducers[action.type](), {
        deep: true,
      })
    : oldState;
};

export default cartReducer;
