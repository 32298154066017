import { forEach, has } from 'lodash/fp';
import assert from 'assert';

// @ts-expect-error - Automatic, Please fix when editing this file
let logger = null;

export default {
  // @ts-expect-error - Automatic, Please fix when editing this file
  getLogger: () => logger,
  // @ts-expect-error - Automatic, Please fix when editing this file
  setLogger: (newLogger) => {
    forEach(
      (level) => {
        assert(has(level, newLogger));
      },
      ['error', 'warn', 'info'],
    );

    logger = newLogger;
  },
};
