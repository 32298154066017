import { getOr } from 'lodash/fp';

import isFeatureEnabled from '../../utils/is-feature-enabled';

const appleBusinessChatIsEnabled = !isFeatureEnabled('disableAppleBusinessChat');

// @ts-expect-error - Automatic, Please fix when editing this file
const selectIsAbcSupportedAndAvailable = (state) =>
  getOr(false, 'livechat.isAbcSupported', state) && appleBusinessChatIsEnabled;

export default selectIsAbcSupportedAndAvailable;
