/**
 * CMS Redux Middleware.
 * Used to set the CMS mode (draft or production).
 */

import { get, isEmpty } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist';

import client from '../client';
import CMS_UPDATE_PREVIEW_OPTIONS from '../action-types';

// @ts-expect-error - Automatic, Please fix when editing this file
const setPreviewOptions = (payload) => {
  if (isEmpty(payload)) {
    return;
  }
  client.setAsAtDate(get('asAtDate', payload));
  client.setAsAtTime(get('asAtTime', payload));
  client.setMode(get('mode', payload));
  client.setDataset(get('dataset', payload));
  client.setApiToken(get('apiToken', payload));
  client.setLiveFilteringToken(get('liveFilteringToken', payload));
};

// @ts-expect-error - Automatic, Please fix when editing this file
const cmsMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case REHYDRATE: {
      if (action.key === 'cms') {
        setPreviewOptions(get('payload', action));
      }
      break;
    }
    case CMS_UPDATE_PREVIEW_OPTIONS: {
      setPreviewOptions(get('payload', action));
      break;
    }
    default:
      break;
  }

  return next(action);
};

export default cmsMiddleware;
