import { applyMiddleware, compose, createStore as createReduxStore } from 'redux';
import { createRouterMiddleware, initialRouterState } from 'connected-next-router';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore } from 'redux-persist';
import Router from 'next/router';
import thunk from 'redux-thunk';

import analyticsMiddleware from './analytics';
import cmsMiddleware from '../cms/middleware';
import createHistory from './create-history';
import createRootReducer from '../reducers';
import getIsDevelopmentEnv from '../utils/get-is-development-env';
import getIsServer from '../utils/get-is-server';
import importedApi from '../models/api';
import promiseMiddleware from './promise-middleware';

/**
 * @param {object} api - API model.
 * @param {object} data - Data to rehydrate with.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
const createStore = (api, data) => {
  let composeEnhancers;
  const isDevelopmentEnv = getIsDevelopmentEnv();
  const isServer = getIsServer();
  if (isDevelopmentEnv && !isServer) {
    /* eslint-disable no-underscore-dangle */
    composeEnhancers =
      // @ts-expect-error - Automatic, Please fix when editing this file
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
      compose;
    /* eslint-enable no-underscore-dangle */
  } else {
    composeEnhancers = compose;
  }

  const middleware = applyMiddleware(
    promiseMiddleware(api),
    analyticsMiddleware,
    thunk,
    createRouterMiddleware(),
    cmsMiddleware,
  );
  const finalCreateStore = composeEnhancers(middleware)(createReduxStore);
  const store = finalCreateStore(createRootReducer(), data);
  const persistor = persistStore(store);

  // We store persistor on the store itself, as we need it for PersistGate.
  // nextReduxWrapper creates the store and persistor but does not make the persistor available to us.
  // This approach is demonstrated in the docs: https://github.com/kirill-konshin/next-redux-wrapper.
  store.__persistor = persistor; // eslint-disable-line no-underscore-dangle

  return { store };
};

export default createStore;

const nextReduxWrapper = createWrapper((context) => {
  // @ts-expect-error - Automatic, Please fix when editing this file
  const { asPath } = context.ctx || Router.router || {};
  const initialState = asPath ? { router: initialRouterState(asPath) } : {};
  // @ts-expect-error - Automatic, Please fix when editing this file
  return createStore(importedApi, initialState, createHistory(context.resolvedUrl)).store;
});

export { nextReduxWrapper };
