import { AnnouncementBanner, LiveChatInitialiser, SkipLink, Toast, Wrapper } from '@ahmdigital/ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, forEach, get, getOr, head, includes, isNil, map, split } from 'lodash/fp';
import { useRouter } from 'next/router';
import cookie from 'cookie';
import React, { useEffect, useRef } from 'react';
import webViewUtils from '@ahmdigital/logic/lib/utils/web-view';

import browserUtil from '../utils/browser';
import CMSAnnouncementBanner from '../components/cms-announcement-banner';
import CMSOverlay from '../components/cms-overlay';
import config from '../config';
import constants from '../ahm-constants';
import defaultFbImage from '../images/default-fb.jpg';
import defaultTwImage from './images/default-tw.jpg';
import delaconScript from '../analytics/delacon';
import Footer from '../elements/footer';
import FooterLinks from '../elements/footer-links';
import FooterPurchase from '../elements/footer-purchase';
import getIsProductionEnv from '../utils/get-is-production-env';
import Head from '../../utils/head';
import Header from '../elements/header';
import isFeatureEnabled from '../utils/is-feature-enabled';
import selectToast from '../selectors/select-toast';
import StructuredDataBlock from '../components/structured-data-block';
import ToastActions from '../actions/toast';
// @ts-expect-error - Automatic, Please fix when editing this file
import toastData from './toast-data';

const description =
  'Simple, smart and affordable health cover for the things you’ll actually use. Hospital, choosable extras and more. Join online.';
const titleComplete = 'Cheap Private Health Insurance - ahm health insurance';

const staticUrl = config.get('static:url');

const headElement = (
  <Head titleTemplate={constants.PAGE_TITLE_TEMPLATE}>
    <title>Cheap private health insurance</title>
    <link rel="manifest" href="/manifest.json" />
    <link rel="preload" href={`${staticUrl}/font/latest/main.css`} as="style" />
    <link rel="icon" href="/favicon.ico" />
    <link rel="stylesheet" href={`${staticUrl}/font/latest/main.css`} type="text/css" />
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="author" content="ahm" />
    <meta name="description" content={description} />
    <meta name="format-detection" content="telephone=no" />
    <meta name="google-site-verification" content="N8UjbrP3WOzHMMt-IJqzGWSr8J_Eza6qpeqs42LmlIk" />
    <meta name="og:description" content={description} />
    <meta name="og:image" content={defaultFbImage.src} />
    <meta name="og:site_name" content="ahm health insurance" />
    <meta name="og:title" content={titleComplete} />
    <meta name="og:type" content="website" />
    <meta name="theme-color" content="#000000" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="@ahmhealth" />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image:src" content={defaultTwImage.src} />
    <meta name="twitter:site" content="@ahmhealth" />
    <meta name="twitter:title" content={titleComplete} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </Head>
);

const toastDelayTime = 1500;

type ApplicationElementProps = {
  actions: {
    toast?: {
      closeToast?: (...args: unknown[]) => unknown;
      openToast?: (...args: unknown[]) => unknown;
      setToastContent?: (...args: unknown[]) => unknown;
    };
  };
  children: React.ReactNode;
  toasts: {
    content?: {
      body?: string;
      title?: string;
    };
    isOpen?: boolean;
  }[];
};

const ApplicationElement = ({
  actions: {
    // @ts-expect-error - Automatic, Please fix when editing this file
    toast: { openToast, closeToast, setToastContent },
  },

  children,
  toasts,
}: ApplicationElementProps) => {
  useEffect(() => {
    forEach((toast) => {
      setToastContent(toast);
      openToast(toast.id);
      // @ts-expect-error - Automatic, Please fix when editing this file
    }, toastData);
  }, []);

  const footerRef = useRef(null);
  const { asPath } = useRouter();
  const path = head(split('?')(asPath));

  delaconScript();

  const doc = browserUtil.getDocument();
  if (webViewUtils.isWebView(cookie.parse(getOr('', 'cookie', doc)))) {
    return (
      <>
        {headElement}
        <main id="main" role="main">
          {children}
        </main>
        <LiveChatInitialiser suffix="DesktopSales" />
        <LiveChatInitialiser suffix="MobileSales" />
      </>
    );
  }

  const isPurchaseFunnel = includes(path, [
    constants.URLS.SALES.BUY,
    constants.URLS.SALES.BUY_ABOUT,
    constants.URLS.SALES.BUY_AGR,
    constants.URLS.SALES.BUY_COVER,
    constants.URLS.SALES.BUY_EMAIL,
    constants.URLS.SALES.BUY_PAYMENT,
  ]);

  const FooterElement = isPurchaseFunnel ? FooterPurchase : Footer;
  const announcementBannerIsVisible =
    isFeatureEnabled('announcementBanner') && includes(path, constants.ANNOUNCEMENT_BANNER_ALLOWLIST);
  const securityAnnouncementBannerIsVisible = includes(path, ['/security-privacy']);

  return (
    <>
      {headElement}
      {!getIsProductionEnv() && <CMSOverlay />}
      <SkipLink to="#main">Skip to main content</SkipLink>
      {announcementBannerIsVisible && (
        <AnnouncementBanner message={config.get('features:announcementBanner:message')} />
      )}
      {securityAnnouncementBannerIsVisible && (
        <AnnouncementBanner message="If you get any suspicious emails or texts, report them to [Scamwatch.](https://www.scamwatch.gov.au/report-a-scam)" />
      )}

      <CMSAnnouncementBanner />

      {map((toast) => {
        // @ts-expect-error - Automatic, Please fix when editing this file
        const rawToastData = find({ id: toast.id }, toastData);

        return (
          !isNil(toast) &&
          includes(path, get('whitelist', rawToastData)) && (
            <Toast
              content={toast.content}
              delayTime={toastDelayTime}
              // @ts-expect-error - Automatic, Please fix when editing this file
              key={toast.id}
              isOpen={toast.isOpen}
              // @ts-expect-error - Automatic, Please fix when editing this file
              onClose={() => closeToast(toast.id)}
            />
          )
        );
      }, toasts)}

      <Wrapper size="extraExtraLarge" isFullWidth>
        <Header isMinimalMenu={isPurchaseFunnel} />
      </Wrapper>
      <div>
        <div id="main" role="main" style={{ display: 'block', outline: 0 }} tabIndex={-1}>
          {children}
        </div>
        <LiveChatInitialiser suffix="DesktopSales" />
        <LiveChatInitialiser suffix="MobileSales" />

        <Wrapper size="extraExtraLarge" isFullWidth>
          <div ref={footerRef}>
            <FooterElement />
          </div>
          {/* @ts-expect-error - Automatic, Please fix when editing this file */}
          <FooterLinks footerRef={footerRef} />
          {/* @ts-expect-error - Automatic, Please fix when editing this file */}
          <StructuredDataBlock />
        </Wrapper>
      </div>
    </>
  );
};

// @ts-expect-error - Automatic, Please fix when editing this file
const mapStateToProps = (state) => ({
  toasts: selectToast(state),
});

// @ts-expect-error - Automatic, Please fix when editing this file
const mapDispatchToProps = (dispatch) => ({
  actions: {
    toast: bindActionCreators(ToastActions, dispatch),
  },
});

// @ts-expect-error - Automatic, Please fix when editing this file
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationElement);

export { ApplicationElement as ApplicationElementForTest, mapStateToProps };
