import { get } from 'lodash/fp';

import PREMIUM_CHANGE from '../../features/premium-change/constants';
import selectRawPrice from '../select-raw-price';

// @ts-expect-error - Automatic, Please fix when editing this file
const selectEffectiveDateOverride = (state) =>
  get('useFutureEffectiveDate', selectRawPrice(state)) ? PREMIUM_CHANGE.FUTURE_EFFECTIVE_DATE : null;

export default selectEffectiveDateOverride;
