import { clone, findIndex, get, includes, set } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { REHYDRATE } from 'redux-persist';

import {
  ADDRESS_CHANGE,
  CONTINUOUS_COVER_CHANGE,
  DATE_OF_BIRTH_CHANGE,
  DEPENDANT_DELETE,
  DEPENDANT_SUBMIT,
  EMAIL_CHANGE,
  FREQUENCY_CHANGE,
  HAS_REFINED_PRICE,
  HAS_SET_BASIC_DETAILS_CHANGE,
  HOSPITAL_EXCESS_CHANGE,
  INCOME_CHANGE,
  INCOME_TIER_CHANGE,
  INCOME_TYPE_CHANGE,
  LAST_VIEWED_PRODUCT_CHANGE,
  PAYMENT_METHOD_CHANGE,
  PRICE_LOAD_SUCCESS,
  PRODUCT_TYPE_CHANGE,
  REBATE_CHANGE,
  SCALE_CHANGE,
  STATE_CHANGE,
  STATE_CHANGE_DEFAULT,
  SWITCHING_CHANGE,
} from '../../actions/action-types';
import constants from '../../ahm-constants';

const {
  RELATIONSHIP: { CLIENT },
} = constants;

// @ts-expect-error - Automatic, Please fix when editing this file
const getStateCode = (code) => code || constants.STATE.NSW;

// @ts-expect-error - Automatic, Please fix when editing this file
const updateMemberField = (state, field, payload, relation = CLIENT) => {
  const memberIndex = findIndex(
    {
      relationship: relation,
    },
    state.members,
  );

  if (memberIndex !== -1) {
    return set(`members[${memberIndex}][${field}]`, payload, state);
  }

  return {
    ...state,
    members: [
      ...state.members,
      {
        [field]: payload,
        relationship: relation,
      },
    ],
  };
};

const initialState = {
  dependants: [],
  frequency: constants.PAYMENT_FREQUENCY.WEEKLY,
  hasRefinedPrice: false,
  hasSetBasicDetails: false,
  hospitalExcess: '750',
  incomeTier: constants.INCOME_TIER.TIER_0,
  incomeType: constants.INCOME_TYPE.SINGLE,
  lhc: 0,
  members: [],
  paymentMethod: constants.PAYMENT_TYPE.BANK,
  rebate: true,
  scale: constants.SCALE.SINGLE,
  // @ts-expect-error - Automatic, Please fix when editing this file
  state: getStateCode(),
  stateChanged: false,
  switching: 'no-selection',
  youthDiscountPercentage: 0,
};
export default function customerReducer(state = initialState, action = {}) {
  const reducers = {
    [ADDRESS_CHANGE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (includes(action.address.residentialAddressState, constants.STATE)) {
        // @ts-expect-error - Automatic, Please fix when editing this file
        return Immutable.asMutable(Immutable.set(state, 'state', action.address.residentialAddressState));
      }

      return state;
    },
    // @ts-expect-error - Automatic, Please fix when editing this file
    [CONTINUOUS_COVER_CHANGE]: () => updateMemberField(state, 'continuous', action.continuous, action.relationship),
    [DATE_OF_BIRTH_CHANGE]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      updateMemberField(state, 'dob', action.dateOfBirth.format(constants.DATE_FORMAT.STANDARD), action.relationship),
    [DEPENDANT_DELETE]: () => {
      const dependants = clone(state.dependants);

      // @ts-expect-error - Automatic, Please fix when editing this file
      dependants.splice(action.dependantIndex, 1);
      return { ...state, dependants };
    },
    [DEPENDANT_SUBMIT]: () => {
      const dependants = clone(state.dependants);

      // @ts-expect-error - Automatic, Please fix when editing this file
      dependants.push(action.payload);
      return { ...state, dependants };
    },
    // @ts-expect-error - Automatic, Please fix when editing this file
    [EMAIL_CHANGE]: () => updateMemberField(state, 'email', action.payload),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [FREQUENCY_CHANGE]: () => ({ ...state, frequency: action.frequency }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [HAS_REFINED_PRICE]: () => ({ ...state, hasRefinedPrice: action.hasRefinedPrice }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [HAS_SET_BASIC_DETAILS_CHANGE]: () => ({ ...state, hasSetBasicDetails: action.hasSetBasicDetails }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [HOSPITAL_EXCESS_CHANGE]: () => ({ ...state, hospitalExcess: action.hospitalExcess }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [INCOME_CHANGE]: () => ({ ...state, income: action.income }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [INCOME_TIER_CHANGE]: () => ({ ...state, incomeTier: action.incomeTier }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [INCOME_TYPE_CHANGE]: () => ({ ...state, incomeType: action.incomeType }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [LAST_VIEWED_PRODUCT_CHANGE]: () => ({ ...state, lastViewedProduct: action.lastViewedProduct }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [PAYMENT_METHOD_CHANGE]: () => ({ ...state, paymentMethod: action.method }),
    [PRICE_LOAD_SUCCESS]: () => ({ ...state, lhc: get('result[0].lhc', action) }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [PRODUCT_TYPE_CHANGE]: () => ({ ...state, productType: action.productType }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [REBATE_CHANGE]: () => ({ ...state, rebate: action.rebate }),
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'customer') {
        // @ts-expect-error - Automatic, Please fix when editing this file
        return { ...initialState, ...state, ...action.payload };
      }

      return state;
    },
    [SCALE_CHANGE]: () => ({
      ...state,
      dependants: [],
      lhc: 0,
      // @ts-expect-error - Automatic, Please fix when editing this file
      scale: action.scale,
    }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [STATE_CHANGE]: () => ({ ...state, state: getStateCode(action.state), stateChanged: true }),
    [STATE_CHANGE_DEFAULT]: () => {
      const newState = { ...state, stateChanged: true };

      if (state.stateChanged) {
        return newState;
      }

      // @ts-expect-error - Automatic, Please fix when editing this file
      newState.state = getStateCode(constants.STATE[action.location.stateCode]);
      return newState;
    },
    // @ts-expect-error - Automatic, Please fix when editing this file
    [SWITCHING_CHANGE]: () => ({ ...state, switching: action.switching }),
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
