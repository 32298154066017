const leadSuccess =
  // @ts-expect-error - Automatic, Please fix when editing this file


    ({ leadType, id }) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    (dispatch) => {
      dispatch({
        recordId: id,
        type: leadType.successAction,
      });
    };

const leadFail =
  // @ts-expect-error - Automatic, Please fix when editing this file


    ({ leadType }) =>
    // @ts-expect-error - Automatic, Please fix when editing this file
    (dispatch) => {
      dispatch({
        type: leadType.failAction,
      });
    };

export default {
  leadFail,
  leadSuccess,
};
