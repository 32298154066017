import { get, last, split, startsWith } from 'lodash/fp';

const BASE_URL = 'https://ahm.com.au';

// @ts-expect-error - Automatic, Please fix when editing this file
const extractRelativePath = (perk) => {
  if (get('descriptionTeaser', perk) && get('identifier.current', perk)) {
    return `/perks/${perk.identifier.current}`;
  }

  const url = get('urlObject.url', perk);
  if (startsWith(BASE_URL, url)) {
    return last(split(BASE_URL, url));
  }

  return url;
};

export default extractRelativePath;
