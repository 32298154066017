import { assign, set } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist';

import { CHOOSABLE_RESET_COMPARER_PRODUCT_ID, CHOOSABLE_SET_COMPARER_PRODUCT_ID } from '../../actions/action-types';

const getDefaultState = () => ({
  comparerProductId: null,
});

// Contains the state for choosable product selection from the product comparer / picker drawer
export default function choosable(state = getDefaultState(), action = {}) {
  // @ts-expect-error - Automatic, Please fix when editing this file
  const { comparerProductId, type } = action;

  const reducers = {
    [CHOOSABLE_RESET_COMPARER_PRODUCT_ID]: () => assign(state, getDefaultState()),
    [CHOOSABLE_SET_COMPARER_PRODUCT_ID]: () => set('comparerProductId', comparerProductId, state),
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'choosable') {
        // @ts-expect-error - Automatic, Please fix when editing this file
        return { ...getDefaultState(), ...state, ...action.payload };
      }

      return state;
    },
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[type] ? reducers[type]() : state;
}
