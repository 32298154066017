import Api from '@ahmdigital/api-client';
// @ts-expect-error - Automatic, Please fix when editing this file
import SSRDummyStorage from '@ahmdigital/api-client/lib/storage/dummy';

import config from '../../config';
import getApiClientConfig from '../../utils/get-api-client-config';
import getIsServer from '../../utils/get-is-server';

const isServer = getIsServer();
const store = isServer ? new SSRDummyStorage() : undefined;

export default new Api(getApiClientConfig(config), store);
