import { flow, map, replace, sortBy, startsWith, uniqBy } from 'lodash/fp';

// @ts-expect-error - Automatic, Please fix when editing this file
const uniqueContentWithDraftPrecedence = (items) =>
  flow(
    // @ts-expect-error - Automatic, Please fix when editing this file
    sortBy((item) => !startsWith('drafts.', item.id)),
    map((item) => ({
      // @ts-expect-error - Automatic, Please fix when editing this file
      ...item,
      // @ts-expect-error - Automatic, Please fix when editing this file
      id: replace('drafts.', '', item.id),
    })),
    uniqBy('id'),
  )(items);

export default uniqueContentWithDraftPrecedence;
