import { get, isNil, omitBy } from 'lodash/fp';

const makeGetCustomData =
  // @ts-expect-error - Automatic, Please fix when editing this file


    ({ reduxStore }) =>
    () => {
      const state = reduxStore.getState();

      const { abtest, customer } = state;

      return {
        custom: omitBy(isNil, {
          abtest,
          customer: customer
            ? omitBy(isNil, {
                frequency: get('frequency', customer),
                incomeTier: get('incomeTier', customer),
                incomeType: get('incomeType', customer),
                lhc: get('lhc', customer),
                scale: get('scale', customer),
                state: get('state', customer),
              })
            : null,
          history: get('history', customer),
        }),
      };
    };

export default makeGetCustomData;
