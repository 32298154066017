import { AB_TEST_UPDATE } from '../../actions/action-types';

const initialState = () => ({});

export default function abtestReducer(state = initialState(), action = { data: {} }) {
  const reducers = {
    [AB_TEST_UPDATE]: () => ({
      ...state,
      ...action.data,
    }),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
