import { includes } from 'lodash/fp';

import { getAllValidProductUrls } from '../../../../routes/utils/get-product-routes';
import constants from '../../../../ahm-constants';
import device from '../../../../utils/device';

const useMessageUs = (pathname: string) => {
  if (includes(pathname, constants.LIVEPERSON.LIVECHAT_URL_BLOCKLIST)) {
    return false;
  }

  const isProductPage = includes(pathname, getAllValidProductUrls());
  return isProductPage ? !device.isTabletOrMobile() : !device.isMobile();
};

export default useMessageUs;
