import { createSelector } from 'reselect';
import { flow, max, values } from 'lodash/fp';

import getAgrAgeBracketFromAge from '../../utils/get-agr-age-bracket-from-age';
import selectCustomerAges from '../select-youth-discount-ages';

// @ts-expect-error - Automatic, Please fix when editing this file
const getAgrBracketFromAges = (ages) => getAgrAgeBracketFromAge(flow(values, max)(ages) || 0);

const selectAgeBracket = createSelector(selectCustomerAges, getAgrBracketFromAges);

export default selectAgeBracket;

export type AgeBracket = string;

export { getAgrBracketFromAges };
