import { isUndefined } from 'lodash/fp';
import { FormikField as RawFormikField } from '@ahmdigital/ui';
import React from 'react';

import getStickyHeaderHeight from '../../utils/get-sticky-header-height';

// @ts-expect-error - Automatic, Please fix when editing this file
const FormikField = (props) => {
  const fixedHeaderHeight = getStickyHeaderHeight();

  return (
    <RawFormikField {...props} scrollToMargin={isUndefined(fixedHeaderHeight) ? undefined : `${fixedHeaderHeight}px`} />
  );
};

export default FormikField;
