import { REHYDRATE } from 'redux-persist';

import { SET_ATTRIBUTION, SET_HAS_PARTNER_REFERRER } from '../../actions/action-types';

const initialState = {};

export default function referrerReducer(state = initialState, action = {}) {
  const reducers = {
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'referrer') {
        return {
          ...state,
          // @ts-expect-error - Automatic, Please fix when editing this file
          ...action.payload,
        };
      }
      return state;
    },

    [SET_ATTRIBUTION]: () => ({
      ...state,
      // @ts-expect-error - Automatic, Please fix when editing this file
      attribution: action.attributionId,
    }),

    // @ts-expect-error - Automatic, Please fix when editing this file
    [SET_HAS_PARTNER_REFERRER]: () => ({ ...state, hasPartnerReferrer: action.hasPartnerReferrer }),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
