import { REBATE_AGE_BRACKET } from '@ahmdigital/constants';
import { toInteger } from 'lodash/fp';

/**
 * @param {number} age - A customer's age.
 * @returns {string} - An AGR bracket from REBATE_AGE_BRACKET.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
const getAgrAgeBracketFromAge = (age) => {
  const numberAge = toInteger(age);
  if (numberAge < 65) {
    return REBATE_AGE_BRACKET.BRACKET_0;
  }
  if (numberAge >= 70) {
    return REBATE_AGE_BRACKET.BRACKET_2;
  }
  return REBATE_AGE_BRACKET.BRACKET_1;
};

export default getAgrAgeBracketFromAge;
