import { findIndex } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import { CLOSE_TOAST, OPEN_TOAST, SET_CONTENT_TOAST } from '../../actions/action-types';

const initialState = {
  toasts: [],
};

export default function drawerReducer(state = initialState, action = {}) {
  const reducers = {
    [CLOSE_TOAST]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      Immutable.setIn(state, ['toasts', findIndex({ id: action.payload.id }, state.toasts), 'isOpen'], false),

    [OPEN_TOAST]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      Immutable.setIn(state, ['toasts', findIndex({ id: action.payload.id }, state.toasts), 'isOpen'], true),

    [SET_CONTENT_TOAST]: () => ({
      toasts: [
        ...state.toasts,
        {
          // @ts-expect-error - Automatic, Please fix when editing this file
          content: action.payload.content,
          // @ts-expect-error - Automatic, Please fix when editing this file
          id: action.payload.id,
          isOpen: false,
        },
      ],
    }),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
