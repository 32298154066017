import { flatMap, join, map, uniq } from 'lodash/fp';
import getParentProductId from '@ahmdigital/logic/lib/utils/get-parent-product-id';

import constants from '../../../ahm-constants';
import getSaleableProductIdsKeyedByType from '../../../utils/get-saleable-product-ids-keyed-by-type';
import uncapFpIterator from '../../../../utils/uncap-fp-iterator';

const productTypeMapping = {
  [constants.PRODUCT_TYPE_SLUG.COMBINED]: constants.PRODUCT_TYPE.COMBINED,
  [constants.PRODUCT_TYPE_SLUG.EXTRAS]: constants.PRODUCT_TYPE.EXTRAS,
  [constants.PRODUCT_TYPE_SLUG.HOSPITAL]: constants.PRODUCT_TYPE.HOSPITAL,
  [constants.PRODUCT_TYPE_SLUG.PACKAGE]: constants.PRODUCT_TYPE.PACKAGE,
};

export const getSalebleSoftBundleProductIdSlugs = () => {
  const saleableProductIdsKeyedByType = getSaleableProductIdsKeyedByType();

  return uniq(
    flatMap((hospitalProductId) => {
      const hospitalParentProductId = getParentProductId(hospitalProductId);

      return map(
        (extrasProductId) => `${hospitalParentProductId}-${extrasProductId}`,
        // @ts-expect-error - Automatic, Please fix when editing this file
        saleableProductIdsKeyedByType[constants.PRODUCT_TYPE.EXTRAS],
      );
      // @ts-expect-error - Automatic, Please fix when editing this file
    }, saleableProductIdsKeyedByType[constants.PRODUCT_TYPE.HOSPITAL]),
  );
};

export const getAllSaleableProductSlugs = () => {
  const saleableProductIdsKeyedByType = getSaleableProductIdsKeyedByType();

  // @ts-expect-error - Automatic, Please fix when editing this file
  return uncapFpIterator(map)((productType, productTypeSlug) => {
    // @ts-expect-error - Automatic, Please fix when editing this file
    const saleableProductIdsForType = saleableProductIdsKeyedByType[productType];

    const productIdSlugs =
      productTypeSlug === constants.PRODUCT_TYPE_SLUG.COMBINED
        ? getSalebleSoftBundleProductIdSlugs()
        : map((productId) => getParentProductId(productId) || productId, saleableProductIdsForType);

    return [productTypeSlug, productIdSlugs];
  }, productTypeMapping);
};

const getProductRoutes = () => {
  const saleableIds = getAllSaleableProductSlugs();

  return flatMap(
    ([type, productSlugs]) => `${constants.URLS.SALES.PRODUCT_LISTING}/:type(${type})/:id(${join('|', productSlugs)})`,
    saleableIds,
  );
};

// Returns all the valid paths in full to match against a Next.JS server-side function
export const getAllValidProductUrls = () => {
  const saleableIds = getAllSaleableProductSlugs();

  return flatMap(
    ([type, productSlugs]) =>
      map((productId) => `${constants.URLS.SALES.PRODUCT_LISTING}/${type}/${productId}`, productSlugs),
    saleableIds,
  );
};

export default getProductRoutes;
