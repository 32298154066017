import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './styles.module.sass';

type RequestACallWrapperProps = {
  children: ReactNode;
  className?: string;
};

// @ts-expect-error - Automatic, Please fix when editing this file
const RequestACallWrapper = ({ children, className = null }: RequestACallWrapperProps) => (
  <div className={styles.wrapperOuter}>
    <div className={styles.wrapperInner}>
      <section className={classNames(styles.form, styles.active, className)}>{children}</section>
    </div>
  </div>
);

export default RequestACallWrapper;
