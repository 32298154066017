import { includes } from 'lodash/fp';

import constants from '../ahm-constants';

const { PRODUCT_TYPE, SCALE } = constants;

//            single  s.parent  couple  family
// combined   2       4         4       4
// package    2       4         4       4
// hospital   1       2         2       2
// extras     1       2         2       2
// ambulance  1       2         2       2
// @ts-expect-error - Automatic, Please fix when editing this file
const getPseu = (type, scale) => {
  const typeMultiplier = includes(type, [PRODUCT_TYPE.COMBINED, PRODUCT_TYPE.PACKAGE]) ? 2 : 1;
  const scaleMultiplier = scale === SCALE.SINGLE ? 1 : 2;
  return typeMultiplier * scaleMultiplier;
};

export default {
  getPseu,
};
