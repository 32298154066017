import { compact, concat, flow, get, includes, intersection, isEmpty, map, set, size, without } from 'lodash/fp';
import { PRODUCT_TYPE } from '@ahmdigital/constants';

import {
  PRODUCT_COMPARER_ACTIVE_INDEX,
  PRODUCT_COMPARER_DIRECT_COMPARISON_PRODUCT_CHANGE,
  PRODUCT_COMPARER_DIRECT_COMPARISON_TOGGLE,
} from '../../actions/action-types';

const getInitialState = () => ({
  directComparisonProductIds: {
    [PRODUCT_TYPE.EXTRAS]: [],
    [PRODUCT_TYPE.HOSPITAL]: [],
    [PRODUCT_TYPE.PACKAGE]: [],
  },
});

const productComparerReducer = (state = getInitialState(), action = {}) => {
  const reducers = {
    [PRODUCT_COMPARER_ACTIVE_INDEX]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      set(`activeIndices[${action.productComparisonId}]`, action.activeIndex, state),

    [PRODUCT_COMPARER_DIRECT_COMPARISON_PRODUCT_CHANGE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { comparerProducts, productType } = action;
      const path = `directComparisonProductIds[${productType}]`;
      const selectedProductIds = get(path, state);
      const comparerProductIds = flow(
        map((comparerProduct) => {
          // @ts-expect-error - Automatic, Please fix when editing this file
          const { parentProductId, id } = comparerProduct;
          // Exclude any products that are not available due to
          // selected excess
          // @ts-expect-error - Automatic, Please fix when editing this file
          if (!isEmpty(comparerProduct.unavailableWithExcessInfo)) {
            return null;
          }
          return parentProductId || id;
        }),
        compact,
      )(comparerProducts);

      if (size(comparerProductIds) < 2) {
        return set(path, [], state);
      }

      return set(path, intersection(selectedProductIds, comparerProductIds), state);
    },

    [PRODUCT_COMPARER_DIRECT_COMPARISON_TOGGLE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { productId, productType } = action;
      const path = `directComparisonProductIds[${productType}]`;
      const selectedProductIds = get(path, state);
      if (includes(productId, selectedProductIds)) {
        return set(path, without([productId], selectedProductIds), state);
      }
      return set(path, concat([productId], selectedProductIds), state);
    },
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
};

export default productComparerReducer;
