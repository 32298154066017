import { tagmanager as TagManager } from '@ahmdigital/analytics';

import { setCookieForMembers } from '../../utils/cookie-for-members';
import config from '../../config';
import getMemberNumber from '../../utils/get-oms-member-number';

const ga4TrackingId = config.get('google:analytics:ga4TrackingId');
export default class GA4Analytics {
  static initialise() {
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.gtag('config', ga4TrackingId);
    GA4Analytics.userId(getMemberNumber());
  }

  /**
   * Set userId for cross device tracking.
   *
   * @param {string} id - The membership number.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static userId(id) {
    if (id) {
      setCookieForMembers('health', { id });

      // GA4
      // @ts-expect-error - Automatic, Please fix when editing this file
      window.gtag('set', {
        // eslint-disable-next-line camelcase
        send_to: ga4TrackingId,
        // eslint-disable-next-line camelcase
        user_id: id,
      });
    }
  }

  /**
   * Track the custom events via both GA4 gtag and the GA4 dataLayer.
   *
   * @param {object} Object - Options.
   * @param {object} Object.data - Object containing event parameters set by the caller.
   * @param {string} Object.name - The name of the event.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static submit({ data = {}, name }) {
    TagManager.submit(name, {
      ...data,
      // eslint-disable-next-line camelcase
      send_to: ga4TrackingId,
    });
  }
}
