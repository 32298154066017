import { omit, set } from 'lodash/fp';
import { REHYDRATE } from 'redux-persist';

import { DISABLE_FEATURE, ENABLE_FEATURE } from '../../actions/action-types';

const initialState = {
  // example: 'example',
};
export default function featureToggleReducer(state = initialState, action = {}) {
  const reducers = {
    [DISABLE_FEATURE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { featureId } = action;

      const newState = omit(featureId, state);

      return newState;
    },
    [ENABLE_FEATURE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { featureId } = action;

      const newState = set(featureId, featureId, state);

      return newState;
    },
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'featureToggle') {
        // @ts-expect-error - Automatic, Please fix when editing this file
        return { ...state, ...action.payload };
      }

      return state;
    },
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
