import getIsServer from './get-is-server';

const breakpoints = {
  lg: 1280,
  md: 1024,
  sm: 768,
  xs: 480,
};

function isMobile() {
  const isServer = getIsServer();
  return !isServer && window.outerWidth <= breakpoints.sm;
}

function isTabletOrMobile() {
  const isServer = getIsServer();
  return !isServer && window.outerWidth <= breakpoints.md;
}

export default {
  isMobile,
  isTabletOrMobile,
};
