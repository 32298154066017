import { createSelector } from 'reselect';
import { get, isNull } from 'lodash/fp';

import enforceCustomerHasCorrectIncomeType from './utils/enforce-customer-has-correct-income-type';
import filterCustomerMembers from './utils/filter-customer-members';
import type { Customer } from './types';

const selectCustomer = createSelector(
  (state) => get('customer', state) || null,
  (rawCustomer): Customer => {
    if (isNull(rawCustomer)) {
      // @ts-expect-error - Automatic, Please fix when editing this file
      return null;
    }

    // we keep both client and spouse in the state, but return
    // spouse only when scale is COUPLE or FAMILY
    const filteredMembers = filterCustomerMembers(rawCustomer);
    return { ...enforceCustomerHasCorrectIncomeType(rawCustomer), members: filteredMembers };
  },
);

export default selectCustomer;
