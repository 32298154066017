import { CLOSE_DRAWER, OPEN_DRAWER, SAVE_AND_RETRIEVE_QUOTE_RESET } from '../action-types';

// @ts-expect-error - Automatic, Please fix when editing this file
const closeDrawer = () => (dispatch) => dispatch({ type: CLOSE_DRAWER });

// @ts-expect-error - Automatic, Please fix when editing this file
const openDrawer = (drawerId) => (dispatch) => dispatch({ id: drawerId, type: OPEN_DRAWER });

// @ts-expect-error - Automatic, Please fix when editing this file
const resetDrawer = () => (dispatch) => dispatch({ type: SAVE_AND_RETRIEVE_QUOTE_RESET });

export default {
  closeDrawer,
  openDrawer,
  resetDrawer,
};
