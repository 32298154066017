import { HISTORY_PUSH } from '../../actions/action-types';

export default function history(state = [], action = {}) {
  const reducers = {
    // @ts-expect-error - Automatic, Please fix when editing this file
    [HISTORY_PUSH]: () => [action.location].concat(state),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
