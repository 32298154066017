import { createTransform } from 'redux-persist';
import { omit } from 'lodash/fp';

// @ts-expect-error - Automatic, Please fix when editing this file
const filterSensitiveValues = (inboundState, key) =>
  key === 'buy' ? omit(['values.password', 'values.declarationAgreement'], inboundState) : inboundState;

// @ts-expect-error - Automatic, Please fix when editing this file
export default createTransform(filterSensitiveValues);
export { filterSensitiveValues };
