import { isNull } from 'lodash/fp';
import getMelbourneTimezoneMoment from '@ahmdigital/logic/lib/utils/get-melbourne-timezone-moment';

import * as dateValidation from '../../../../utils/date-validation';
import constants from '../../../../ahm-constants';

// @ts-expect-error - Automatic, Please fix when editing this file
const getValidCoverStartDate = ({ coverStartDate } = {}) => {
  const isCoverStartDateValid = isNull(dateValidation.coverStartDate()(coverStartDate));

  if (isCoverStartDateValid) {
    return coverStartDate;
  }

  const now = getMelbourneTimezoneMoment();
  return now.format(constants.DATE_FORMAT.STANDARD);
};

export default getValidCoverStartDate;
