import { includes, trim } from 'lodash/fp';

import * as utils from './utils';
import { DIMENSIONS, EVENTS, USER_TYPE, VALID_EVENTS_ECOMMERCE } from './constants';
import { setCookieForMembers } from '../../utils/cookie-for-members';
import config from '../../config';
import getGeoLocation from '../../features/get-geo-location';
import getMemberNumber from '../../utils/get-oms-member-number';

export default class GoogleAnalytics {
  /**
   * Inserts the google analytics tracking script.
   *
   * @param {string} trackingId - Google urchin code.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  constructor(trackingId) {
    /*eslint-disable */
    (function (i, s, o, g, r, a, m) {
      // @ts-expect-error - Automatic, Please fix when editing this file
      i['GoogleAnalyticsObject'] = r;
      // @ts-expect-error - Automatic, Please fix when editing this file
      (i[r] =
        // @ts-expect-error - Automatic, Please fix when editing this file
        i[r] ||
        function () {
          // @ts-expect-error - Automatic, Please fix when editing this file
          (i[r].q = i[r].q || []).push(arguments);
        }),
        // @ts-expect-error - Automatic, Please fix when editing this file
        (i[r].l = 1 * new Date());
      // @ts-expect-error - Automatic, Please fix when editing this file
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      // @ts-expect-error - Automatic, Please fix when editing this file
      a.async = 1;
      // @ts-expect-error - Automatic, Please fix when editing this file
      a.src = g;
      // @ts-expect-error - Automatic, Please fix when editing this file
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */

    // Initialise the tracking with tracking Id
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('create', trackingId, 'auto', { allowLinker: true }, { siteSpeedSampleRate: 10 });

    // Enable the enhanced ecommerce
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('require', 'ec');

    // Enable Google Optimize.
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('require', config.get('google:optimize:containerId'));

    // Set currency to AUD
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('set', '&cu', 'AUD');

    // Tracking when people go to car and home portal
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('require', 'linker');
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('linker:autoLink', ['portal.beopen.com']);

    // @ts-expect-error - Automatic, Please fix when editing this file
    this.EVENTS = EVENTS;
    // @ts-expect-error - Automatic, Please fix when editing this file
    this.ecommercePushTimer = null;
    // @ts-expect-error - Automatic, Please fix when editing this file
    this.ga4TrackingId = config.get('google:analytics:ga4TrackingId');

    // GA methods are not available right away, you should always invoke
    // them using the ga command queue's ready callback.
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga(GoogleAnalytics.onReady);
  }

  static onReady() {
    // @ts-expect-error - Automatic, Please fix when editing this file
    GoogleAnalytics.clientId(window.ga.getAll()[0].get('clientId'));
    // `_mem` (membership number, car and home) cookie is set in OMS or after purchase
    GoogleAnalytics.userId(getMemberNumber());
    getGeoLocation().then(({ ipAddress }) => {
      GoogleAnalytics.dimension(DIMENSIONS.ipAddress, ipAddress);
    });
  }

  /**
   * Set userId for cross device tracking.
   *
   * @param {string} id - The membership number.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static userId(id) {
    if (id) {
      GoogleAnalytics.dimension(DIMENSIONS.membershipNewOrExisting, USER_TYPE.member);
      GoogleAnalytics.dimension(DIMENSIONS.membershipId, id);
      setCookieForMembers('health', { id });
      // @ts-expect-error - Automatic, Please fix when editing this file
      window.ga('set', 'userId', id);
    } else {
      GoogleAnalytics.dimension(DIMENSIONS.membershipNewOrExisting, USER_TYPE.prospect);
    }
  }

  /**
   * Set clientId (google analytics client id).
   *
   * @param {string} id - The GA clientId.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static clientId(id) {
    GoogleAnalytics.dimension(DIMENSIONS.clientId, id);
  }

  /**
   * Set the value of a dimension.
   *
   * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/custom-dims-mets | Custom Dimensions}
   * @param {string} property - The dimension’s name/key/property.
   * @param {string} value - The dimension’s value.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static dimension(property, value) {
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('set', trim(`dimension${property}`), trim(value));
  }

  /**
   * Track the visit to a particular page path.
   *
   * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/pages | Pages}
   * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications | SPAs}
   * @param {string} path - The path to the page viewed.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static pageview(path) {
    // Sets the page value on the tracker so later hits use it
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('set', 'page', trim(path));
    // Sending the page view, uses the tracker value above
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('send', 'pageview');
  }

  /**
   * Track the custom events.
   *
   * @param {object} Object - Options.
   * @param {string} Object.action - The event action to track.
   * @param {string} Object.category -.
   * @param {object} Object.data - Can have keys `label`, `value`, `nonInteraction`.
   * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events | Events}
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  static event({ action, category, data = {} }) {
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga('send', {
      eventAction: utils.toTitleCase(action),
      eventCategory: utils.toTitleCase(category),
      // @ts-expect-error - Automatic, Please fix when editing this file
      eventLabel: utils.toTitleCase(data.label),
      // @ts-expect-error - Automatic, Please fix when editing this file
      eventValue: data.value,
      hitType: 'event',
      // @ts-expect-error - Automatic, Please fix when editing this file
      nonInteraction: data.nonInteraction,
    });
  }

  /**
   * Assist with recording ecommerce analytics.
   * NOTE: These enhanced ecommerce events don’t get sent unless a pageview or event occurs after them.
   * That is why `event('ecommerce', 'trigger');` runs after each.
   *
   * @param {string} action - The event action to track.
   * @param {object|string=} data - Can have keys `label`, `value`, `nonInteraction`.
   * @param {object=} args - Extra information to pass to GA.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  ecommerce(action, data, args) {
    if (!includes(action, VALID_EVENTS_ECOMMERCE)) {
      /* eslint-disable no-console */
      console.warn('Invalid google enhanced ecommerce action', { action });
      /* eslint-enable no-console */
      return;
    }
    // Publish action and its data
    // @ts-expect-error - Automatic, Please fix when editing this file
    window.ga(`ec:${action}`, data, args);

    // Push the ecommerce events, max once per second
    // Remove existing timeout request
    // @ts-expect-error - Automatic, Please fix when editing this file
    clearTimeout(this.ecommercePushTimer);
    // Only push ecommerce changes, once every second
    // @ts-expect-error - Automatic, Please fix when editing this file
    this.ecommercePushTimer = setTimeout(() => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      window.ga('send', 'event', 'Ecommerce', 'Push', 'Events', 0, true);
    }, 1000);
  }
}
