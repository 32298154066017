import { get } from 'lodash/fp';

import {
  BUILD_YOUR_COVER_EXTRAS_SERVICES_CHANGE,
  BUILD_YOUR_COVER_HOSPITAL_SERVICES_CHANGE,
  BUILD_YOUR_COVER_STEP_CHANGE,
} from '../../actions/action-types';

type StateType = {
  activeStep: string | null;
  selectedExtrasServices: string[];
  selectedHospitalServices: string[];
};

type ActionType = {
  activeStep?: string;
  selectedExtrasServices?: string[];
  selectedHospitalServices?: string[];
  type?: string;
};

const initialState = {
  activeStep: null,
  selectedExtrasServices: [],
  selectedHospitalServices: [],
} as StateType;

const buildYourCoverReducer = (state: StateType = initialState, action: ActionType = {}) => {
  const reducers = {
    [BUILD_YOUR_COVER_EXTRAS_SERVICES_CHANGE]: () => ({
      ...state,
      selectedExtrasServices: action.selectedExtrasServices,
    }),

    [BUILD_YOUR_COVER_HOSPITAL_SERVICES_CHANGE]: () => ({
      ...state,
      selectedHospitalServices: action.selectedHospitalServices,
    }),

    [BUILD_YOUR_COVER_STEP_CHANGE]: () => ({
      ...state,
      activeStep: action.activeStep,
    }),
  };

  if (!action.type) {
    return state;
  }

  const reducer = get(action.type, reducers);
  return reducer ? reducer() : state;
};

export default buildYourCoverReducer;
