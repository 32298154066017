import { pick } from 'lodash/fp';

import { LOCATION_LOAD, LOCATION_LOAD_FAIL, LOCATION_LOAD_SUCCESS } from '../../actions/action-types';

const initialState = () => ({
  ipAddress: null,
  loading: true,
  stateCode: null,
});

export default function location(state = initialState(), action = {}) {
  const reducers = {
    [LOCATION_LOAD]: () => ({
      ...state,
      loading: true,
    }),

    [LOCATION_LOAD_FAIL]: () => ({
      ...state,
      loading: false,
    }),

    [LOCATION_LOAD_SUCCESS]: () => ({
      ...state,
      // @ts-expect-error - Automatic, Please fix when editing this file
      ...pick(['ipAddress', 'stateCode'], action.location),
      loading: false,
    }),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
