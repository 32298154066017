import { getOr } from 'lodash/fp';

/**
 * Provides api (the Api instance) to the action’s promise function (if it exists).
 * Handles APIpromise based requests (success and failure).
 *
 * @param {object} api - The api to use the middleware with.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
export default function promiseMiddleware(api) {
  // @ts-expect-error - Automatic, Please fix when editing this file
  return (/* {dispatch, getState} */) => (next) => (action) => {
    const { promise, types, ...rest } = action;

    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });
    return (
      promise(api)
        // @ts-expect-error - Automatic, Please fix when editing this file
        .then((result) => next({ ...rest, result, type: SUCCESS }))
        // @ts-expect-error - Automatic, Please fix when editing this file
        .catch((responseData) => {
          const error = getOr(responseData, 'response.body', responseData); // remove detailed request data
          const finalError = getOr(error, 'error', error); // reduce nested error properties
          return next({ ...rest, error: finalError, type: FAILURE });
        })
    );
  };
}
