import { actionTypes, reducer as formReducer } from 'redux-form';
import { forEach, get, includes, isEmpty, pick } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { REHYDRATE } from 'redux-persist';

import {
  ADDRESS_CHANGE,
  BUY_SUBMIT,
  EMAIL_CHANGE,
  FREQUENCY_CHANGE,
  INCOME_TIER_CHANGE,
  INCOME_TYPE_CHANGE,
  PAYMENT_SUBMIT_SUCCESS,
  REBATE_CHANGE,
  SCALE_CHANGE,
  STATE_CHANGE,
} from '../../actions/action-types';
import constants from '../../ahm-constants';
import formLogging from '../../utils/form-logging';
import getValidBuyFormDates from './utils/get-valid-buy-form-dates';
import getValidPaymentStartDate from './utils/get-valid-payment-start-date';

const clearForm = () => undefined;

function buyFormReducer(state = {}, action = {}) {
  const reducers = {
    [actionTypes.CHANGE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.meta.form === 'buy') {
        // @ts-expect-error - Automatic, Please fix when editing this file
        if (action.meta.field === 'coverStartsToday') {
          // @ts-expect-error - Automatic, Please fix when editing this file
          const { paymentStartDate } = state.values;
          return Immutable.asMutable(
            Immutable.merge(
              state,
              {
                // @ts-expect-error - Automatic, Please fix when editing this file
                values: getValidBuyFormDates({
                  paymentStartDate,
                }),
              },
              {
                deep: true,
              },
            ),
          );
        }

        // @ts-expect-error - Automatic, Please fix when editing this file
        if (action.meta.field === 'coverStartDate') {
          // @ts-expect-error - Automatic, Please fix when editing this file
          const { paymentStartDate } = state.values;
          return Immutable.asMutable(
            Immutable.setIn(
              state,
              ['values', 'paymentStartDate'],
              getValidPaymentStartDate({
                // @ts-expect-error - Automatic, Please fix when editing this file
                coverStartDate: action.payload,
                paymentStartDate,
              }),
            ),
          );
        }
      }

      return state;
    },
    [ADDRESS_CHANGE]: () =>
      Immutable.asMutable(
        Immutable.merge(
          state,
          {
            // @ts-expect-error - Automatic, Please fix when editing this file
            values: action.address,
          },
          {
            deep: true,
          },
        ),
      ),
    [BUY_SUBMIT]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { data } = action;

      // Ensure that the start date that is hidden from the user is valid on submission
      if (data.coverStartsToday) {
        const values = getValidBuyFormDates(pick(['coverStartDate', 'paymentStartDate'], data));
        return Immutable.asMutable(
          Immutable.merge(
            state,
            {
              values,
            },
            {
              deep: true,
            },
          ),
        );
      }

      return state;
    },
    // @ts-expect-error - Automatic, Please fix when editing this file
    [EMAIL_CHANGE]: () => Immutable.asMutable(Immutable.setIn(state, ['values', 'email'], action.payload)),
    [FREQUENCY_CHANGE]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      Immutable.asMutable(Immutable.setIn(state, ['values', 'paymentFrequency'], action.frequency)),
    [INCOME_TIER_CHANGE]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      Immutable.asMutable(Immutable.setIn(state, ['values', 'incomeTier'], action.incomeTier)),
    [INCOME_TYPE_CHANGE]: () =>
      // @ts-expect-error - Automatic, Please fix when editing this file
      Immutable.asMutable(Immutable.setIn(state, ['values', 'incomeType'], action.incomeType)),
    [PAYMENT_SUBMIT_SUCCESS]: clearForm,
    // @ts-expect-error - Automatic, Please fix when editing this file
    [REBATE_CHANGE]: () => Immutable.asMutable(Immutable.setIn(state, ['values', 'rebateConfirmation'], action.rebate)),
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'form' && includes(get('payload.buy.values.scale', action), constants.SCALE)) {
        formLogging({
          // @ts-expect-error - Automatic, Please fix when editing this file
          data: action.payload.buy.values,
          identifier: 'buyForm REHYDRATE',
          isOptionalMedicare: true,
          level: 'info',
        });
        // @ts-expect-error - Automatic, Please fix when editing this file
        const hydratedState = Immutable.merge(state, action.payload.buy);

        // Reset date fields so they start valid.
        const values = getValidBuyFormDates(pick(['coverStartDate', 'paymentStartDate'], get('values', hydratedState)));
        return Immutable.asMutable(
          Immutable.merge(
            hydratedState,
            {
              initial: values,
              values,
            },
            {
              deep: true,
            },
          ),
        );
      }

      return state;
    },
    [SCALE_CHANGE]: clearForm,
    [STATE_CHANGE]: () => {
      let newState = Immutable(state);

      // @ts-expect-error - Automatic, Please fix when editing this file
      if (Immutable.getIn(newState, ['values', 'residentialAddressState']) !== action.state) {
        newState = Immutable.merge(
          newState,
          {
            values: {
              // @ts-expect-error - Automatic, Please fix when editing this file
              residentialAddressState: action.state,
            },
          },
          {
            deep: true,
          },
        );

        // Clear out the other address fields if the state changes
        forEach(
          (fieldName) => {
            newState = Immutable.setIn(newState, ['values', fieldName], '');
            newState = Immutable.setIn(newState, ['fields', fieldName], {}); // Clear visited and touched.
          },
          [
            'residentialAddress',
            'residentialAddressLine1',
            'residentialAddressLine2',
            'residentialAddressPostCode',
            'residentialAddressSuburb',
          ],
        );
      }

      if (Immutable.getIn(newState, ['values', 'postalMatchesResidentialAddress']) === 'yes') {
        // @ts-expect-error - Automatic, Please fix when editing this file
        newState = Immutable.setIn(newState, ['values', 'postalAddressState'], action.state);
      }

      return Immutable.asMutable(newState);
    },
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}

function paymentFormReducer(state = {}, action = {}) {
  const reducers = {
    [REHYDRATE]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (action.key === 'form' && !isEmpty(get('payload.payment.values', action))) {
        // @ts-expect-error - Automatic, Please fix when editing this file
        return Immutable.asMutable(Immutable.merge(state, action.payload.payment));
      }

      return state;
    },
    [SCALE_CHANGE]: clearForm,
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}

export default formReducer.plugin({
  buy: buyFormReducer,
  payment: paymentFormReducer,
});
export { buyFormReducer, paymentFormReducer };
