import { defaults } from 'lodash/fp';

import {
  SAVE_AND_RETRIEVE_QUOTE_FAIL,
  SAVE_AND_RETRIEVE_QUOTE_RESET,
  SAVE_AND_RETRIEVE_QUOTE_SUCCESS,
} from '../../actions/action-types';

const initialState = () => ({ failed: false, submitted: false });

export default function saveAndRetrieveQuote(state = initialState(), action = {}) {
  const reducers = {
    [SAVE_AND_RETRIEVE_QUOTE_FAIL]: () => defaults(state, { failed: true }),
    [SAVE_AND_RETRIEVE_QUOTE_RESET]: () => initialState(),
    [SAVE_AND_RETRIEVE_QUOTE_SUCCESS]: () => defaults(state, { submitted: true }),
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
