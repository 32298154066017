const LEAD_CAPTURE_FIELDS = {
  AGE: 'age',
  DATE_OF_BIRTH: 'dob',
  EMAIL: 'email',
  EXCESS: 'excess',
  FREQUENCY: 'frequency',
  HAS_CASH_ASSIST_INTEREST: 'hasCashAssistInterest',
  INCOME: 'income',
  INCOME_BRACKET: 'incomeBracket',
  INCOME_TIER: 'incomeTier',
  INCOME_TYPE: 'incomeType',
  IS_MEMBER: 'isMember',
  MARKETING_OPT_IN: 'marketingOptIn',
  NAME: 'name',
  PARTNER_AGE: 'partnerAge',
  PARTNER_DOB: 'partnerDob',
  PHONE: 'phone',
  PRICE: 'price',
  PRODUCT: 'product',
  PRODUCT_TYPE: 'productType',
  REBATE_AGE_BRACKET: 'rebateAgeBracket',
  REBATE_AGE_TIER: 'rebateAgeTier',
  REBATE_PERCENTAGE: 'rebatePercentage',
  SCALE: 'scale',
  STATE: 'state',
  URL: 'url',
  YOUTH_DISCOUNT_PERCENTAGE: 'youthDiscountPercentage',
};

export default LEAD_CAPTURE_FIELDS;
