import { EXTRAS_TYPE } from '@ahmdigital/constants';
import { get, getOr, includes, isEmpty, map } from 'lodash/fp';

import { EVENTS, VALID_EVENTS_ECOMMERCE_GA4 } from './google/constants';
import GA4Analytics from './ga4-analytics';
import PriceService from '../models/price';
import ProductService from '../models/product';
import selectCalculatedYouthDiscountPercentage from '../selectors/select-calculated-youth-discount-percentage';
import selectSwitchingMessageBundle from '../pages/buy/components/buy-form/components/steps/success/components/switching-message/selectors/select-switching-message-bundle';
import utils from './utils';

/* eslint-disable camelcase */
// snake_case keys are used here as per GA spec
// @ts-expect-error - Automatic, Please fix when editing this file
const itemMapper = (product, price) => ({
  item_brand: 'PHI',
  item_category: get('type', product),
  item_category2: get('name', product),
  item_category3: get('extrasType', product) === EXTRAS_TYPE.CHOOSABLE ? get('shortName', product) : '',
  item_id: get('id', product),
  item_name: get('name', product),
  item_variant: get('charges.excess.amount', product) ? `${get('charges.excess.amount', product)} excess` : 'no excess',
  price,
  quantity: 1,
});
/* eslint-enable camelcase */

// @ts-expect-error - Automatic, Please fix when editing this file
const getPriceFromPriceObject = (priceObject, frequency) =>
  priceObject ? PriceService.getAmountAsFloat(priceObject, frequency) : undefined;

// @ts-expect-error - Automatic, Please fix when editing this file
const ecommerceHandlerGA4 = (data, state) => {
  const type = get('type', data);

  if (!includes(type, VALID_EVENTS_ECOMMERCE_GA4)) {
    throw new Error(`Unsupported GA4 ecommerce event type '${type}'`);
  }

  const scale = get('customer.scale', state);
  const frequency = get('customer.frequency', state);
  const coupon = isEmpty(get('coupon', data)) ? 'no' : get('coupon', data);
  const lhc = get('customer.lhc', state);
  const hasLhc = lhc > 0 ? 'yes' : 'no';
  const prices = get('price.data', state);

  const calculatedYouthDiscountPercentage = selectCalculatedYouthDiscountPercentage(state);

  const hasYouthDiscount = calculatedYouthDiscountPercentage > 0 ? 'yes' : 'no';

  /* eslint-disable camelcase */
  const userProperties = {
    household_income_type: getOr('unknown', 'customer.incomeType', state),
    income_tier: get('customer.incomeTier', state),
    previous_health_fund: getOr('unknown', 'checkout.buy.existingHealthFund', state),
    state: getOr('', 'customer.state', state),
  };

  const basePayload = {
    ...userProperties,
    coupon,
    currency: 'AUD',
    lifetime_health_cover_loading: hasLhc,
    payment_frequency: frequency,
    scale,
    youth_discount: hasYouthDiscount,
  };
  /* eslint-enable camelcase */

  // view_item_list is handled separately in this if statement,
  // because the items array includes many products rather than one.
  if (type === EVENTS.ECOMMERCE_GA4.VIEW_ITEM_LIST) {
    const products = get('products', data);
    const customer = get('customer', data);
    const productType = get('products[0].type', data);

    const items = map((product) => {
      const priceObject = PriceService.getPrice(prices, get('id', product), customer);
      const price = getPriceFromPriceObject(priceObject, 'yearly');
      return itemMapper(product, price);
    })(products);

    const viewItemListPayload = {
      ...basePayload,
      items,
      pseu: productType && scale ? utils.getPseu(productType, scale) : undefined,
    };

    GA4Analytics.submit({ data: viewItemListPayload, name: EVENTS.ECOMMERCE_GA4.VIEW_ITEM_LIST });
    return;
  }

  const product =
    get('product', data) || ProductService.getProduct(get('product.data', state), get('cart.product.id', state));
  const productId = get('id', product);
  const priceObject = getOr(PriceService.getPrice(prices, productId, data.customer), 'price', data);
  const transactionId = `${productId}:${Date.now()}`;
  const yearlyPrice = getPriceFromPriceObject(priceObject, 'yearly');
  const recurringPrice = getPriceFromPriceObject(priceObject, frequency);
  const productType = get('type', product);
  const item = itemMapper(product, yearlyPrice);

  /* eslint-disable camelcase */
  const payload = {
    ...basePayload,
    items: [item],
    pseu: productType && scale ? utils.getPseu(productType, scale) : undefined,
    recurring_price: recurringPrice,
    value: yearlyPrice,
  };

  if (type === EVENTS.ECOMMERCE_GA4.PURCHASE) {
    // Existing health fund is cleared from checkout object in redux so we can grab it from the membership object instead
    const previousHealthFund = getOr(
      'unknown',
      'customers.client.existingHealthFund',
      selectSwitchingMessageBundle(state),
    );

    GA4Analytics.submit({
      data: {
        ...payload,
        membership_id: get('membership.membershipId', state),
        previous_health_fund: previousHealthFund,
        transaction_id: transactionId,
        user_properties: {
          ...userProperties,
          membership_id: get('membership.membershipId', state),
          previous_health_fund: previousHealthFund,
        },
      },
      name: type,
    });
    return;
  }
  /* eslint-enable camelcase */

  GA4Analytics.submit({ data: payload, name: type });
};

export default ecommerceHandlerGA4;
