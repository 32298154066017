// @ts-expect-error - Automatic, Please fix when editing this file
import { createBrowserHistory, createMemoryHistory } from 'history';
import { get, unset } from 'lodash/fp';
import { parse, stringify } from 'qs';
import qhistory from 'qhistory';

import getIsServer from '../utils/get-is-server';

// @ts-expect-error - Automatic, Please fix when editing this file
const createHistory = (initialPath) => {
  let history;

  const isServer = getIsServer();
  if (isServer) {
    const opts = initialPath ? { initialEntries: [initialPath] } : undefined;
    history = createMemoryHistory(opts);
  } else {
    history = createBrowserHistory();
  }
  const result = qhistory(history, stringify, parse);

  if (get('location.state', result) === undefined) {
    // this fixes an issue with serialising history during SSR
    unset('location.state', result);
  }
  return result;
};

export default createHistory;
