import { bindActionCreators } from 'redux';
import { Clickable } from '@ahmdigital/ui';
import { connect } from 'react-redux';
import { flow, isNil, merge, omitBy } from 'lodash/fp';
import { Formik } from 'formik';
import React from 'react';

import { LEAD_TYPES } from '../../models/lead-capture/constants';
import { LeadInput } from '../../types/leads';
import adjustFormValuesForLeadType from './utils/adjust-form-values-for-lead-type';
import constants from '../../ahm-constants';
import createLead from '../../utils/create-lead';
import FormikField from '../formik-field-with-scroll-margin';
import generateSchema from './schema';
import LeadCaptureFormActions from '../../actions/lead-capture-form';
import LeadForm from './components/lead-form';
import RequiredFieldsDisclaimer from '../../pages/buy/components/buy-form/components/required-fields-disclaimer';
import selectLeadCapture from '../../selectors/select-lead-capture';
import styles from './styles.module.sass';
import type { CaptureFormState, LeadActions, LeadFormData, LeadType } from './types';

type LeadCaptureFormProps = {
  analyticsLabel?: string;
  additionalLeadFields?: {
    age?: number;
    excess?: number;
    frequency?: string;
    url?: string;
    state?: string;
    price?: number;
    product?: string;
  };
  actions: LeadActions;
  captureFormState?: CaptureFormState;
  leadInput: LeadInput;
  getEmail?: (submittedEmail: string) => void;
  leadType: LeadType;
  onSubmit?: (values: LeadFormData) => void;
  sourceType: string;
  submitButtonText: string;
  isLoading?: boolean;
};

const sharedFieldProps = {
  size: 'auto',
  variant: styles.field,
};

const ahmMemberOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const declarationLabel = (
  <>
    I agree to the terms of ahm’s{' '}
    <Clickable to={constants.URLS.SALES.PRIVACY} inNewTab>
      privacy policy
    </Clickable>
  </>
);

// @ts-expect-error - Automatic, Please fix when editing this file
const getFields = (component, setFieldTouched, setFieldValue) => {
  switch (component) {
    case LEAD_TYPES.REQUEST_A_CALL.component:
      return (
        <>
          <FormikField {...sharedFieldProps} label="Name" name="name" type="text" isRequired />
          <FormikField {...sharedFieldProps} label="Contact number" name="phone" type="tel" isRequired />
          <FormikField
            {...sharedFieldProps}
            name="isMember"
            label="Are you a current ahm member?"
            inputVariant="button"
            type="radioGroup"
            options={ahmMemberOptions}
            onBlur={() => setFieldTouched('isMember', true, false)}
            // @ts-expect-error - Automatic, Please fix when editing this file
            onChange={(val) => setFieldValue('isMember', val)}
          />
          <FormikField
            {...sharedFieldProps}
            hasContainerID
            fieldLabel={declarationLabel}
            name={`privacyAgreement-${component}`}
            type="checkbox"
            isRequired
            variant={styles.checkbox}
          />
        </>
      );
    case LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component:
      return (
        <>
          <RequiredFieldsDisclaimer />
          <FormikField
            {...sharedFieldProps}
            isRequired
            label="Email address"
            maxLength="255"
            name="email"
            placeholder="name@example.com"
            type="email"
          />
          <FormikField
            {...sharedFieldProps}
            className={styles.fieldWithSuffixText}
            isRequired
            label="Name"
            name="name"
            placeholder="Name"
            suffixText="So we can personalise your cover email."
            type="text"
          />
          <FormikField
            {...sharedFieldProps}
            name="isMember"
            label="Are you a current ahm member?"
            inputVariant="button"
            type="radioGroup"
            options={ahmMemberOptions}
            onBlur={() => setFieldTouched('isMember', true, false)}
            // @ts-expect-error - Automatic, Please fix when editing this file
            onChange={(val) => setFieldValue('isMember', val)}
          />
          <FormikField
            {...sharedFieldProps}
            className={styles.fieldWithSuffixText}
            label="Phone (optional)"
            name="phone"
            placeholder="Phone number"
            suffixText="So we can call you to see if you have any questions."
            type="tel"
          />
        </>
      );
    case LEAD_TYPES.ABANDONED_CART_PRE_BUY_FORM.component:
      return (
        <>
          <FormikField
            {...sharedFieldProps}
            hasContainerID
            isRequired
            label="Email address"
            maxLength="255"
            name="email"
            placeholder="name@example.com"
            type="email"
          />
          <FormikField
            {...sharedFieldProps}
            hasContainerID
            fieldLabel={declarationLabel}
            name={`privacyAgreement-${component}`}
            type="checkbox"
            isRequired
          />
        </>
      );
    default:
      return null;
  }
};

const checkEmpty = (value: boolean | string | number) => value === '' || isNil(value);

const LeadCaptureForm = ({
  analyticsLabel,
  additionalLeadFields = {},
  actions,
  leadInput,
  getEmail,
  leadType,
  onSubmit,
  sourceType,
  submitButtonText,
  captureFormState,
  isLoading,
}: LeadCaptureFormProps) => {
  const { packagedSchema } = generateSchema(leadType.component);
  return (
    <Formik
      {...packagedSchema}
      // @ts-expect-error - Automatic, Please fix when editing this file
      onSubmit={async (values) => {
        const adjustedValues = adjustFormValuesForLeadType({ leadType, values });

        if (onSubmit) {
          onSubmit(adjustedValues);
        }

        const leadFormData = flow(
          merge(additionalLeadFields),
          merge(leadInput),
          omitBy(checkEmpty),
        )(adjustedValues) as LeadInput;

        try {
          const leadId = await createLead({ leadInput: leadFormData, sourceType });
          actions.leadCaptureForm.leadSuccess({ id: leadId, leadType });

          if (getEmail) {
            // @ts-expect-error - Automatic, Please fix when editing this file
            getEmail(leadFormData.email);
          }
        } catch (error) {
          actions.leadCaptureForm.leadFail({ leadType });
        }
      }}
    >
      <LeadForm
        analyticsLabel={analyticsLabel}
        captureFormState={captureFormState}
        // @ts-expect-error - Automatic, Please fix when editing this file
        getFields={getFields}
        leadType={leadType}
        // @ts-expect-error - Automatic, Please fix when editing this file
        sharedFieldProps={sharedFieldProps}
        submitButtonText={submitButtonText}
        isLoading={isLoading}
      />
    </Formik>
  );
};

// @ts-expect-error - Automatic, Please fix when editing this file
const mapDispatchToProps = (dispatch) => ({
  actions: {
    leadCaptureForm: bindActionCreators(LeadCaptureFormActions, dispatch),
  },
});

// @ts-expect-error - Automatic, Please fix when editing this file
const mapStateToProps = (state) => ({
  // passing through empty object as second parameter because the selectLeadCapture selector requires it for
  // use with the abandoned cart checkout action (this should be decoupled from the selector)
  leadInput: selectLeadCapture(state, {}),
});

// @ts-expect-error - Automatic, Please fix when editing this file
export default connect(mapStateToProps, mapDispatchToProps)(LeadCaptureForm);
