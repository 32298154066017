import { get } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import config from '../config';
import device from './device';

const engagementDevice = device.isMobile() ? 'mobile' : 'desktop';
const allLiveChatConfig = config.get('liveChat');
const liveChatEngagementId = get(`engagementId.${engagementDevice}`, allLiveChatConfig);
const liveChatConfig = Immutable.set(allLiveChatConfig, 'engagementId', liveChatEngagementId);

export default liveChatConfig;
