import { FOOTER_ADJUST_FOOTER_LINKS, FOOTER_SET_VISIBILITY } from '../action-types';

// @ts-expect-error - Automatic, Please fix when editing this file
const setAdjustFooterLinks = (adjustFooterLinks) => (dispatch) => {
  dispatch({ payload: adjustFooterLinks, type: FOOTER_ADJUST_FOOTER_LINKS });
};

// @ts-expect-error - Automatic, Please fix when editing this file
const setVisibility = (isVisible) => (dispatch) => {
  dispatch({ payload: isVisible, type: FOOTER_SET_VISIBILITY });
};

export default { setAdjustFooterLinks, setVisibility };
