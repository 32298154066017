import { isEmpty, some } from 'lodash/fp';

import logger from '../../../../logging';

// @ts-expect-error - Automatic, Please fix when editing this file
const generateSanityUri = ({ dataset, projectId, tag, useCdn }) => {
  if (some(isEmpty, [dataset, projectId, tag])) {
    logger.getLogger().error('Missing required values for sanity api generation', {
      dataset,
      projectId,
      tag,
    });
  }
  return `https://${projectId}.api${useCdn ? 'cdn' : ''}.sanity.io/v1/graphql/${dataset}/${tag}`;
};

export default generateSanityUri;
