// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import { FOOTER_ADJUST_FOOTER_LINKS, FOOTER_SET_VISIBILITY } from '../../actions/action-types';

const footerReducer = (state = { adjustFooterLinks: false }, action = {}) => {
  const reducers = {
    // @ts-expect-error - Automatic, Please fix when editing this file
    [FOOTER_ADJUST_FOOTER_LINKS]: () => Immutable.set(state, 'adjustFooterLinks', action.payload),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [FOOTER_SET_VISIBILITY]: () => Immutable.set(state, 'isVisible', action.payload),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
};

export default footerReducer;
