import { includes } from 'lodash/fp';

import { FooterStaticProps } from '../../types';
import constants from '../../../../ahm-constants';
import formatUrlPath from '../format-url-path';
import getCarHomeInsuranceProps, { CAR_HOME_PATHS } from '../get-car-home-insurance-props';
import getDefaultProps from '../get-default-props';
import getIncomeProtectionInsuranceProps from '../get-income-protection-insurance-props';
import getLifeInsuranceProps from '../get-life-insurance-props';
import getTravelInsuranceProps from '../get-travel-insurance-props';

const determineFooterProps = (pathname: string): FooterStaticProps => {
  const basePath = formatUrlPath({ pathname });

  if (basePath === formatUrlPath({ pathname: constants.URLS.SALES.LIFE_INSURANCE })) {
    return getLifeInsuranceProps();
  }

  if (basePath === formatUrlPath({ pathname: constants.URLS.SALES.INCOME_INSURANCE })) {
    return getIncomeProtectionInsuranceProps();
  }

  if (basePath === formatUrlPath({ pathname: constants.URLS.SALES.TRAVEL_INSURANCE })) {
    return getTravelInsuranceProps();
  }

  if (includes(basePath)(CAR_HOME_PATHS)) {
    // @ts-expect-error - Automatic, Please fix when editing this file
    return getCarHomeInsuranceProps({ basePath });
  }

  // @ts-expect-error - Automatic, Please fix when editing this file
  return getDefaultProps(pathname);
};

export default determineFooterProps;
