import { ApolloClient, HttpLink } from '@apollo/client';
import { get, includes, isEmpty, isNull, join, memoize, pick, values } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';
import { InMemoryCache } from '@apollo/client/cache';
import { RetryLink } from '@apollo/client/link/retry';
// @ts-expect-error - Automatic, Please fix when editing this file
import fetch from 'node-fetch';
import moment from 'moment';

import { DATASET, MIDDAY, MODE, NOW } from '../constants';
import browserUtil from '../../utils/browser';
import config from '../../config';
import constants from '../../ahm-constants';
import generateLiveFilteringApi from './utils/generate-live-filtering-api-uri';
import generateSanityUri from './utils/generate-sanity-uri';
import getIsProductionEnv from '../../utils/get-is-production-env';
import getIsServer from '../../utils/get-is-server';
import logger from '../../logging';
import unfixFpIterator from '../../../utils/unfix-fp-iterator';

const FILTERED_SUFFIX = '_filtered';

const sanityConfig = config.get('sanity');

// @ts-expect-error - Automatic, Please fix when editing this file
const addAuthHeader = (options, token) => Immutable.setIn(options, ['headers', 'Authorization'], `Bearer ${token}`);

// This creates the CMS client for the client side logic and CMS interactions, with the server having a similar copy of this
// code. Any changes done to this file needs to be replicated on the server's CMS client as well (particularly the createClient
// and getUri methods). These would most probably get combined when Next.js is implemented.
// @ts-expect-error - Automatic, Please fix when editing this file
const createClient = (dataset, mode, uri, token, fetchPolicy) => {
  const isServer = getIsServer();
  const cache = new InMemoryCache();
  const isTokenRequired = token && mode === MODE.DRAFT;

  if (!isServer) {
    cache.restore(get('__APOLLO_STATE_CMS__', browserUtil.getWindow()));
  }

  const httpLink = new HttpLink({
    fetch: isTokenRequired ? (url, options) => fetch(url, addAuthHeader(options, token)) : fetch,
    uri,
  });
  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      if (count < 5) {
        return true;
      }
      logger
        .getLogger()
        .error('Failed multiple retries to retrieve data from CMS', { error, isServer: getIsServer(), operation });
      return false;
    },
  });

  const link = retryLink.concat(httpLink);

  const opts = {
    cache,
    connectToDevTools: get('NODE_ENV', config) !== 'production',
    defaultOptions: {
      query: {
        fetchPolicy,
      },
      watchQuery: {
        fetchPolicy,
      },
    },
    link,
    name: 'sanity-sales',
    ssrMode: isServer,
  };
  return new ApolloClient(opts);
};
// @ts-expect-error - Automatic, Please fix when editing this file
const memoizedCreateClient = unfixFpIterator(memoize)(createClient, (...args) => join('|', args));

class Client {
  projectId = sanityConfig.projectId;

  tag = sanityConfig.tag;

  useCdn = sanityConfig.useCdn;

  useFiltered = sanityConfig.useFiltered;

  mode = MODE.PUBLISHED;

  dataset = sanityConfig.dataset;

  asAtDate = NOW;

  asAtTime = MIDDAY;

  token = null;

  liveFilteringToken = null;

  useLiveFiltering() {
    return !getIsProductionEnv() && !isNull(this.liveFilteringToken);
  }

  /**
   * @returns {ApolloClient} - Returns an ApolloClient instance, will be memoized client-side.
   */
  get() {
    const isServer = getIsServer();
    return (isServer ? createClient : memoizedCreateClient)(
      this.dataset,
      this.mode,
      this.getUri(),
      this.token,
      this.getFetchPolicy(),
    );
  }

  /**
   * @param  {string} mode - One of 'draft' or 'published'.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  setMode(mode) {
    if (includes(mode, values(MODE))) {
      this.mode = mode;
    }
  }

  /**
   * @param  {string} dataset - One of 'staging' or 'production'.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  setDataset(dataset) {
    if (includes(dataset, values(DATASET))) {
      this.dataset = dataset;
    }
  }

  /**
   * @param  {string} asAtDate - Date-string dd/mm/yyyy or 'NOW'.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  setAsAtDate(asAtDate) {
    if (!isEmpty(asAtDate)) {
      this.asAtDate = asAtDate;
    }
  }

  /**
   * @param  {string} asAtTime - Time-string hh:mm.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  setAsAtTime(asAtTime) {
    if (!isEmpty(asAtTime)) {
      this.asAtTime = asAtTime;
    }
  }

  /**
   * @param  {string} apiToken - the api token required to access draft data.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  setApiToken(apiToken) {
    if (!isEmpty(apiToken)) {
      this.token = apiToken;
    }
  }

  /**
   * @param  {string} liveFilteringToken - the api token required to perform live filtering aka time travel.
   */
  // @ts-expect-error - Automatic, Please fix when editing this file
  setLiveFilteringToken(liveFilteringToken) {
    if (!isEmpty(liveFilteringToken)) {
      this.liveFilteringToken = liveFilteringToken;
    }
  }

  /**
   * @returns {string} - Returns an endpoint uri for the client instance, with token.
   */
  getUri() {
    if (!this.useLiveFiltering() || this.asAtDate === NOW) {
      const dataset = `${this.dataset}${this.useFiltered ? FILTERED_SUFFIX : ''}`;
      return generateSanityUri({ dataset, projectId: this.projectId, tag: this.tag, useCdn: this.useCdn });
    }

    const asAtMoment = moment(`${this.asAtDate} ${this.asAtTime}`, `${constants.DATE_FORMAT.STANDARD} HH:mm`);
    const dateTimeString = `${asAtMoment.format('YYYYMMDDTHHmmSS')}`;
    return generateLiveFilteringApi({
      ...pick(['endpoint'], sanityConfig.liveFilteringApi),
      asAtDateTime: dateTimeString,
      dataset: this.dataset,
      mode: this.mode,
      token: this.liveFilteringToken,
    });
  }

  /**
   * @returns {string} - Returns the fetchPolicy to be applied to the client.
   */
  getFetchPolicy() {
    const isServer = getIsServer();
    const isTimeTravelling = this.useLiveFiltering() && this.asAtDate !== NOW;
    const clientFetchPolicy = isTimeTravelling ? sanityConfig.fetchPolicy.timeTravel : sanityConfig.fetchPolicy.client;
    return isServer ? sanityConfig.fetchPolicy.server : clientFetchPolicy;
  }
}

export default new Client();
export { addAuthHeader };
