import { CLOSE_MODAL, OPEN_MODAL } from '../../actions/action-types';

const initialState = {
  visible: null,
};

export default function modalReducer(state = initialState, action = {}) {
  const reducers = {
    [CLOSE_MODAL]: () => ({
      ...state,
      visible: null,
    }),

    [OPEN_MODAL]: () => ({
      ...state,
      // @ts-expect-error - Automatic, Please fix when editing this file
      visible: action.id,
    }),
  };

  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
