import { filter, get, isArray, replace } from 'lodash/fp';
import NextHead from 'next/head';
import React from 'react';

import ahmConstants from '../client/ahm-constants';
import config from '../client/config';

// @ts-expect-error - Automatic, Please fix when editing this file
const findNodeContent = (elements, type) => elements.find((element) => get('type', element) === type);
// @ts-expect-error - Automatic, Please fix when editing this file
const stripTitleNode = (elements) => filter((element) => get('type', element) !== 'title', elements);
// @ts-expect-error - Automatic, Please fix when editing this file
const findNodeByName = (elements, name) => elements.find((element) => get('props.name', element) === name);

type HeadProps = {
  children: React.ReactNode;
  titleTemplate?: string;
};

const Head = ({ children, titleTemplate }: HeadProps) => {
  const titleNode = findNodeContent(isArray(children) ? children : [children], 'title');
  const expectedTitle = titleNode ? titleNode.props.children : 'Cheap private health insurance';

  // @ts-expect-error - Automatic, Please fix when editing this file
  const formattedTitle = replace('%s', expectedTitle, titleTemplate);

  const remainingHeadNodes = stripTitleNode(isArray(children) ? children : [children]);

  const descriptionNode = findNodeByName(isArray(children) ? children : [children], 'description');
  const description = descriptionNode
    ? descriptionNode.props.content
    : 'Simple, smart and affordable health cover for the things you’ll actually use. Hospital, choosable extras and more. Join online.';

  const staticUrl = config.get('static:url');

  return (
    <NextHead>
      <title>{formattedTitle}</title>
      <link rel="preload" href={`${staticUrl}/font/latest/main.css`} as="style" />
      <link rel="stylesheet" href={`${staticUrl}/font/latest/main.css`} type="text/css" />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      {remainingHeadNodes}
    </NextHead>
  );
};

Head.defaultProps = {
  titleTemplate: ahmConstants.PAGE_TITLE_TEMPLATE,
};

export default Head;
