import { forEach } from 'lodash/fp';

import { PRODUCT_LOAD, PRODUCT_LOAD_FAIL, PRODUCT_LOAD_SUCCESS } from '../../actions/action-types';
import makeErrorSerialisable from '../../utils/make-error-serialisable';

const initialState = {
  data: null,
  error: null,
  loading: {},
};
export default function productReducer(state = initialState, action = {}) {
  const reducers = {
    [PRODUCT_LOAD]: () => {
      const loading = { ...state.loading };

      forEach((productId) => {
        // @ts-expect-error - Automatic, Please fix when editing this file
        loading[productId] = true;
        // @ts-expect-error - Automatic, Please fix when editing this file
      }, action.productIds);

      return { ...state, loading };
    },
    [PRODUCT_LOAD_FAIL]: () => {
      const loading = { ...state.loading };
      // @ts-expect-error - Automatic, Please fix when editing this file
      const { productIds } = action;

      // @NOTE: Products that aren't loaded aren't handled in any meanigful way.
      forEach((productId) => {
        // @ts-expect-error - Automatic, Please fix when editing this file
        delete loading[productId];
      }, productIds);

      return {
        ...state,
        // @ts-expect-error - Automatic, Please fix when editing this file
        error: makeErrorSerialisable(action.error),
        loading,
      };
    },
    [PRODUCT_LOAD_SUCCESS]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      const data = { ...state.data };
      const loading = { ...state.loading };
      // @ts-expect-error - Automatic, Please fix when editing this file
      const products = action.result;

      // Update data with each Product object from the results.
      forEach((product) => {
        data[product.id] = product;
        // @ts-expect-error - Automatic, Please fix when editing this file
        delete loading[product.id];
      }, products);

      return {
        ...state,
        data,
        error: null, // @NOTE: It is weird that this would clear all errors
        loading,
      };
    },
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
