import { createSelector } from 'reselect';
import { get, getOr, includes, isEmpty, isNaN, isNil, omitBy, overSome, toNumber, toSafeInteger } from 'lodash/fp';
import qs from 'qs';

import { INCOME_TIER_NONE } from '../../components/pricing-drawer/constants';
import constants from '../../ahm-constants';
import getIncomeBracket from '../../utils/get-income-bracket';
import getMemberAge from '../../utils/get-member-age';
import hasHospitalComponent from '../../utils/has-hospital-component';
import selectAgeBracket from '../select-age-bracket';
import selectAgeBracketOption from '../select-age-bracket-option';
import selectCalculatedYouthDiscountPercentage from '../select-calculated-youth-discount-percentage';
import selectCart from '../select-cart';
import selectCartPrice from '../select-cart-price';
import selectCheckout from '../select-checkout';
import selectCustomer from '../select-customer';
import selectLocation from '../select-location';
import selectMembership from '../select-membership';
import selectRebatePercentage from '../select-rebate-percentage';

// @ts-expect-error - Automatic, Please fix when editing this file
const selectLeadForm = (state, leadFormData) => leadFormData;

// @ts-expect-error - Automatic, Please fix when editing this file
const stringifyQuery = ({ age, frequency, hospitalExcess, incomeTier, incomeType, partnerAge, scale, state }) => {
  const finalQuery = qs.stringify(
    {
      age,
      frequency,
      hospitalExcess,
      incomeTier,
      incomeType,
      partnerAge,
      scale,
      state,
    },
    { skipNulls: true },
  );

  return finalQuery !== '' ? `?${finalQuery}` : '';
};

// @ts-expect-error - Automatic, Please fix when editing this file
const isExcessApplicable = (input, productUrl) => includes(input, productUrl);

const selectLeadCapture = createSelector(
  [
    selectAgeBracket,
    selectAgeBracketOption,
    selectCalculatedYouthDiscountPercentage,
    selectCartPrice,
    selectCart,
    selectCheckout,
    selectCustomer,
    selectLocation,
    selectLeadForm,
    selectMembership,
    selectRebatePercentage,
  ],
  (
    ageBracket,
    ageBracketOption,
    calculatedYouthDiscountPercentage,
    cartPrice,
    cart,
    checkout,
    customer,
    location,
    leadFormData,
    membership,
    rebatePercentage,
  ) => {
    const { PRODUCT_TYPE, RELATIONSHIP, SCALE, URLS } = constants;

    const age = toSafeInteger(getMemberAge({ customer, relationship: RELATIONSHIP.CLIENT })) || null;
    const hospitalExcess = get('hospitalExcess', customer);
    const rebate = get('rebate', customer);
    const incomeTier = rebate ? get('incomeTier', customer) : INCOME_TIER_NONE;
    const incomeType = get('incomeType', customer) === SCALE.SINGLE ? `${SCALE.SINGLE}` : `${SCALE.FAMILY}`;
    const frequency = get('frequency', customer);
    const leadLocation = getOr(null, 'location', leadFormData);
    const partnerAge = toSafeInteger(getMemberAge({ customer, relationship: RELATIONSHIP.SPOUSE })) || null;
    const scale = get('scale', customer);
    const state = get('state', customer);
    const queryParams = {
      age,
      frequency,
      ...(isExcessApplicable(PRODUCT_TYPE.HOSPITAL, leadLocation || location) && {
        hospitalExcess,
      }),
      incomeTier,
      incomeType,
      partnerAge,
      scale,
      state,
    };

    const stringifiedQueries = `${stringifyQuery(queryParams)}&`;
    const url = leadLocation ? `${leadLocation}${stringifiedQueries}` : `${location}${stringifiedQueries}`;
    const getCartDetailsFromMembership =
      includes(URLS.SALES.BUY_SUCCESS, `/${url}`) && !isEmpty(get('membershipId', membership));
    const price = get(`amount.${frequency}`, cartPrice);
    const productType = getCartDetailsFromMembership ? get('cart.product.type', membership) : get('product.type', cart);
    const sendExcess = productType
      ? !includes(PRODUCT_TYPE.EXTRAS, productType)
      : isExcessApplicable(PRODUCT_TYPE.HOSPITAL, url);

    const getProductFromState = getCartDetailsFromMembership ? get('cart.product', membership) : get('product', cart);
    const isYouthDiscountApplicable = productType
      ? hasHospitalComponent(getProductFromState)
      : isExcessApplicable(PRODUCT_TYPE.HOSPITAL, url);
    const youthDiscountPercentage = isYouthDiscountApplicable ? calculatedYouthDiscountPercentage : null;
    const getDob = getCartDetailsFromMembership
      ? getOr(null, 'members[0].dob', customer)
      : getOr(null, 'buy.dob', checkout);
    const getPartnerDob = getCartDetailsFromMembership
      ? getOr(null, 'members[1].dob', customer)
      : getOr(null, 'buy.partnerDob', checkout);

    return omitBy(overSome([isNil, isNaN]), {
      age,
      dob: age && getDob,
      email: get('email', leadFormData),
      excess: hospitalExcess && sendExcess ? toNumber(hospitalExcess) : null,
      frequency,
      income: get('income', customer),
      incomeBracket: getIncomeBracket(incomeTier, get('rebate', customer), incomeType),
      incomeTier,
      incomeType,
      isMember: getOr(false, 'isMember', leadFormData),
      marketingOptIn: getOr(false, 'marketingOptIn', leadFormData),
      name: get('name', leadFormData),
      partnerAge,
      partnerDob: partnerAge && getPartnerDob,
      phone: get('phone', leadFormData),
      price: price ? toNumber(price) : null,
      product: get('name', getProductFromState),
      productType,
      rebateAgeBracket: ageBracketOption,
      rebateAgeTier: ageBracket,
      rebatePercentage,
      scale,
      state,
      url,
      youthDiscountPercentage,
    });
  },
);

export default selectLeadCapture;
