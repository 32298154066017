import { clone, defaults } from 'lodash/fp';
import moment from 'moment';

import {
  REQUEST_A_CALL_CAPTURE,
  REQUEST_A_CALL_COLLAPSE,
  REQUEST_A_CALL_DISMISS,
  REQUEST_A_CALL_EXPAND,
  REQUEST_A_CALL_FAIL,
  REQUEST_A_CALL_RESET,
  REQUEST_A_CALL_RULE_BLOCK,
  REQUEST_A_CALL_RULE_TRIGGER,
  REQUEST_A_CALL_SHOW,
  REQUEST_A_CALL_SUCCESS,
} from '../../actions/action-types';

const initialState = () => ({
  captured: false,
  dismissed: false,
  expanded: false,
  failed: false,
  visible: false,
});

export default function requestACall(state = initialState(), action = {}) {
  const reducers = {
    [REQUEST_A_CALL_CAPTURE]: () => ({ ...state, failed: false, submitted: moment().format() }),
    [REQUEST_A_CALL_COLLAPSE]: () =>
      defaults(state, {
        expanded: false,
      }),
    [REQUEST_A_CALL_DISMISS]: () =>
      defaults(state, {
        // @ts-expect-error - Automatic, Please fix when editing this file
        captured: Boolean(state.submitted),
        dismissed: moment().format(),
        failed: false,
      }),
    [REQUEST_A_CALL_EXPAND]: () =>
      defaults(state, {
        expanded: true,
      }),
    [REQUEST_A_CALL_FAIL]: () =>
      defaults(state, {
        failed: true,
      }),
    [REQUEST_A_CALL_RESET]: () => initialState(),
    [REQUEST_A_CALL_RULE_BLOCK]: () => ({ ...state, rule: null, visible: false }),
    // @ts-expect-error - Automatic, Please fix when editing this file
    [REQUEST_A_CALL_RULE_TRIGGER]: () => ({ ...state, rule: action.rule, visible: false }),
    [REQUEST_A_CALL_SHOW]: () => {
      // @ts-expect-error - Automatic, Please fix when editing this file
      if (state.rule === action.rule) {
        const newState = clone(state);

        newState.visible = true;

        // @ts-expect-error - Automatic, Please fix when editing this file
        if (action.reset) {
          newState.captured = false;
          newState.dismissed = false;
          // @ts-expect-error - Automatic, Please fix when editing this file
          newState.submitted = false;
          newState.expanded = false;
        }

        return newState;
      }

      return state;
    },
    // @ts-expect-error - Automatic, Please fix when editing this file
    [REQUEST_A_CALL_SUCCESS]: () => ({ ...state, recordId: action.recordId }),
  };
  // @ts-expect-error - Automatic, Please fix when editing this file
  return reducers[action.type] ? reducers[action.type]() : state;
}
