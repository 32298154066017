import { CLOSE_MODAL, OPEN_MODAL } from '../action-types';

// @ts-expect-error - Automatic, Please fix when editing this file
const closeModal = (modalId) => (dispatch) => dispatch({ id: modalId, type: CLOSE_MODAL });

// @ts-expect-error - Automatic, Please fix when editing this file
const openModal = (modalId) => (dispatch) => dispatch({ id: modalId, type: OPEN_MODAL });

export default {
  closeModal,
  openModal,
};
