import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { get } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import fetch from 'node-fetch';

import browserUtil from '../../utils/browser';
import config from '../../config';
import getIsServer from '../../utils/get-is-server';

const createClient = () => {
  const isServer = getIsServer();
  const cache = new InMemoryCache();
  if (!isServer) {
    cache.restore(get('__APOLLO_STATE__', browserUtil.getWindow()));
  }
  const apiUrl = config.get('api:url');
  const linkOpts = { fetch, uri: `${apiUrl}/graphql` };
  const opts = {
    cache,
    link: createHttpLink(linkOpts),
    name: get('data.app', config),
    ssrMode: isServer,
  };
  return new ApolloClient(opts);
};

const client = createClient();
export default client;
